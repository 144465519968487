jQuery(document).ready( function($) {

    $('.participant__item__vote__button').on('click' , function(e){
        var video = $( this ).attr('data-video');
        var image = $( this ).attr('data-image');
        if('' !== video){
            $('#vote .participant__entry iframe').attr('src' , video);
            $('#vote .participant__entry').fadeIn('fast');
            $('#vote .participant__entry img').fadeOut('fast');
            $('#vote .participant__entry .iframe_wrapper').fadeIn('fast');
            $('#vote .participant__entry h3 span').text($(this).attr('data-name'));
        }else if('' !== image){
            $('#vote .participant__entry img').attr('src' , image);
            $('#vote .participant__entry').fadeIn('fast');
            $('#vote .participant__entry .iframe_wrapper').fadeOut('fast');
            $('#vote .participant__entry img').fadeIn('fast');
            $('#vote .participant__entry h3 span').text($(this).attr('data-name'));
        }else{
            $('#vote .participant__entry').fadeOut('fast');
            $('#vote .participant__entry img').fadeOut('fast');
            $('#vote .participant__entry .iframe_wrapper').fadeOut('fast');
        }
        $('.participant__voting-form').fadeIn('fast');
        $('html, body').animate({
            scrollTop: $('#vote .vote-anchor').offset().top
        }, 1000);
    });

});